<template>
  <div>
    <b-row>
      <b-col cols="2" v-for="(slot,index) of config_data.value" :key="index">
        <slot-price 
          :price="slot.price" 
          :index="index" 
          @price_change="priceChange"
        />
      </b-col>
    </b-row>
    <div class="d-flex justify-content-center">
      <b-button variant="outline-primary" pill @click="create">
        <span class="align-middle">Create new</span>
        <feather-icon
          icon="PlusIcon"
          class="ml-50"
        />
      </b-button>
    </div>
    <div style="text-align: right">
      <b-button @click="updateSlotPrice" variant="success">Update</b-button>
    </div>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import SlotPrice from './SlotPrice.vue'
import service from '../../service'
export default {
  components:{
    ValidationProvider,
    ValidationObserver,
    SlotPrice
  },
  props:{
    config_data: {type: Object, default: null}
  },
  methods:{
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async updateSlotPrice(){
      if (this.$store.state.dropRateConfig.slotPriceValidation.length > 0){
        this.$store.dispatch("pushErrorNotify", {
          text: "Inventory max slot validate error",
        });
      }
      else{
        let data = this.config_data;
        await service.update(
          JSON.stringify(data)
        );
      }
    },
    priceChange(val){
      this.config_data.value[val.index].price = val.price;
      if (val.price < 0 || isNaN(val.price)){
        this.$store.commit("dropRateConfig/ADD_SLOT_PRICE","input-slot-"+val.index+"-feedback")
      }
      else{
        this.$store.commit("dropRateConfig/REMOVE_SLOT_PRICE","input-slot-"+val.index+"-feedback")
      }
    },
    create(){
      this.config_data.value.push({price: 0})
    }
  }
}
</script>