<template>
  <div>
    <validation-observer
      ref="statUpdateCost"
    > 
      <validation-provider
        name="Stat update cost"
        v-slot="validationContext"
        :rules="{ required: true, min_value: 0}"
      >
        <b-form-group label="Stat update cost" label-for="h-number">
          <b-form-input
            v-model="config_data.value"
            :state="getValidationState(validationContext)"
            type="number"
            aria-describedby="input-daily-limit-feedback"
          />
          <b-form-invalid-feedback id="input-stat-update-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <div style="text-align: right">
        <b-button @click="updateStatCost" variant="success">Update</b-button>
      </div>
    </validation-observer>
    
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import service from '../../service'
export default {
  components:{
    ValidationProvider,
    ValidationObserver
  },
  props:{
    config_data: {type: Object, default: null}
  },
  methods:{
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async updateStatCost(){
      let validation = await this.$refs.statUpdateCost.validate()
      if (!validation){
        this.$store.dispatch("pushErrorNotify", {
          text: "Stat update cost validate error",
        });
      }
      else{
        let data = this.config_data;
        data.value = parseInt(data.value);
        await service.update(
          JSON.stringify(data)
        );
      }
    },
  }
}
</script>