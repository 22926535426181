<template>
  <div>
    <validation-observer
      ref="dropRate"
    > 
      <validation-provider
        name="Drop rate"
        v-slot="validationContext"
        :rules="{ required: true, min_value: 0}"
      >
        <b-form-group label="Drop rate" label-for="h-number">
          <b-input-group size="lg" prepend="%">
            <b-form-input
              v-model="config_data.value"
              :state="getValidationState(validationContext)"
              type="number"
              aria-describedby="input-drop-rate-feedback"
            />
            <b-form-invalid-feedback id="input-drop-rate-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </validation-provider>
      <div style="text-align: right">
        <b-button @click="updateDropBoxRate" variant="success">Update</b-button>
      </div>
      
    </validation-observer>
    
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import service from '../../service'
export default {
  components:{
    ValidationProvider,
    ValidationObserver
  },
  props:{
    config_data: {type: Object, default: null}
  },
  methods:{
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async updateDropBoxRate(){
      let validation = await this.$refs.dropRate.validate()
      if (!validation){
        this.$store.dispatch("pushErrorNotify", {
          text: "Hidden mission drop box rate validate error",
        });
      }
      else{
        let data = this.config_data;
        data.value = parseInt(data.value);
        await service.update(
          JSON.stringify(data)
        );
      }
    },
  }
}
</script>