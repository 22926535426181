<template>
  <div v-if="configData" class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb
          title="Config"
          class="mb-3"
        />
      </b-col>
    </b-row>
    <div id="item-config">
      <h2>Item configs</h2>
      <b-card>
        <h4>Drop rate config</h4>
        <div v-for="(config, index) in configData.UNBOX_DROP_RATES.value" :key="index" class="mb-2">
          <config-collapse-item
            :config="config"
            :collapse_id="config.attrs.type"
            @item_rate_check="itemRateCheck"
          />
        </div>
        <div>
          <b-button @click="updateDropRate" variant="success" style="float: right">Update</b-button>
        </div>
      </b-card>
    </div>
    <div id="daily-limit">
      <h2>Hidden mission configs</h2>
      <b-card>
        <div class="mb-2 config-dash-border">
          <h4>Hidden mission daily limit</h4>
          <hidden-mission-daily-limit
            :config_data="configData.HIDDEN_MISSION_DAILY_LIMIT"
          />
        </div>
        <div class="config-dash-border">
          <h4>Hidden mission drop box rate</h4>
          <hidden-mission-drop-box-rate
            :config_data="configData.HIDDEN_MISSION_BOX_DROP_RATE"
          />
        </div>
      </b-card>
    </div>
    <div id="stat-effect">
      <h2>Stat configs</h2>
      <b-card>
        <div class="config-dash-border">
          <h4>Stat effect</h4>
          <stat-effect
            :config_data="configData.STATS_EFFECT"
          />
        </div>
        <div class="config-dash-border mt-2">
          <h4>Stat update cost</h4>
          <stat-update-cost :config_data="configData.STATS_UPDATE_COST" />
        </div>
      </b-card>
    </div>
    <div>
      <h2>Inventory configs</h2>
      <b-card>
        <div class="config-dash-border mb-2">
          <h4>Inventory max slot</h4>
          <inventory-max-slot :config_data="configData.INVENTORY_SLOT_MAX" />
        </div>
        <div class="config-dash-border">
          <h4>Inventory slot price</h4>
          <inventory-slot-price :config_data="configData.INVENTORY_SLOT_PRICE" />
        </div>
      </b-card>
    </div>
    <div>
      <h2>Ruby</h2>
      <b-card>
        <div class="config-dash-border mb-2">
          <h4>Ruby price</h4>
          <ruby-price :config_data="configData.RUBY_PRICE" />
        </div>
        <div class="config-dash-border mb-2">
          <h4>Ruby unbox cost</h4>
          <unbox-cost :config_data="configData.UNBOX_PRICE" />
        </div>
      </b-card>
    </div>
    <action-buttons
      back-button
    />
  </div>
</template>
<script>
import service from '../service'
import ConfigCollapseItem from './_components/ConfigCollapseItem.vue'
import HiddenMissionDailyLimit from './_components/HiddenMissionDailyLimit.vue'
import HiddenMissionDropBoxRate from './_components/HiddenMissionDropBoxRate'
import StatEffect from './_components/StatEffect'
import StatUpdateCost from './_components/StatUpdateCost.vue'
import InventoryMaxSlot from './_components/InventorySlotMax.vue'
import InventorySlotPrice from './_components/InventorySlotPrice.vue'
import RubyPrice from './_components/RubyPrice.vue'
import UnboxCost from './_components/UnboxPrice.vue'
export default {
  components:{
    ConfigCollapseItem,
    HiddenMissionDailyLimit,
    HiddenMissionDropBoxRate,
    StatEffect,
    StatUpdateCost,
    InventoryMaxSlot,
    InventorySlotPrice,
    RubyPrice,
    UnboxCost,
  },
  data(){
    return{
      configData: null,
      isUpdating: false,
      hiddenMissionValidate: true,
      hiddenMissionDropBoxRateValidate: true,
      statEffectValidate: true
    }
  },
  created(){
    this.getConfig();
  },
  methods: {
    async getConfig(){
      let query = {
        category:["item","quest"],
        key: ["UNBOX_DROP_RATES","STATS_EFFECT","HIDDEN_MISSION_BOX_DROP_RATE","HIDDEN_MISSION_DAILY_LIMIT","STATS_UPDATE_COST","INVENTORY_SLOT_MAX","INVENTORY_SLOT_PRICE","RUBY_PRICE","UNBOX_PRICE"],
      };
      let response = await service.getList(
        {
          query: JSON.stringify(query),
          order_by: 'createdAt',
          order: 1
        }
      );
      // this.configData = response.data.data.list;
      let configObject = {}
      for (let i of response.data.data.list){
        configObject[i.key] = i;
      }
      this.configData = configObject
    },
    async updateDropRate(){
      if (this.$store.state.dropRateConfig.dropRateValidation.length > 0){
        this.$store.dispatch("pushErrorNotify", {
          text: "Total variant, tier rate must be equal 100",
        });
      }
      // else if (!this.hiddenMissionValidate || !this.hiddenMissionDropBoxRateValidate || !this.statEffectValidate){
      //   this.$store.dispatch("pushErrorNotify", {
      //     text: "Validate error",
      //   });
      // }
      else{
        this.isUpdating = true;
        let data = service.parseIntRate(this.configData[2])
        await service.update(
          JSON.stringify(data)
        );
        this.isUpdating = false;
      }
    },
    itemRateCheck(){
      let totalRate = 0;
      for (let i=0; i<this.configData.value.length; i++){
        totalRate += parseInt(this.configData.value[i].rate);
      }
      if (totalRate != 60){
        this.$store.commit("dropRateConfig/ADD_UNVALIDATE_RATE", "item-config")
        document.getElementById("item-config").style.border = "2px solid red";
        document.getElementById("item-config").style.padding = "5px";
      }
      else{
        this.$store.commit("dropRateConfig/REMOVE_UNVALIDATE_RATE", "item-config")
        document.getElementById("item-config").style.border = null;
        document.getElementById("item-config").style.padding = "0px";
      }
    },
    findConfigDataIndex(key){
      let index = this.configData.findIndex((x)=>{
        return x.key == key;
      })
      return index
    }
  }
}
</script>
<style scoped>
  .config-dash-border{
    padding: 5px;
    border: 1px dashed gray;
  }
</style>