<template>
  <div>
    <validation-observer
      ref="slotPrice"
    > 
      <validation-provider
        name="Inventory slot price"
        v-slot="validationContext"
        :rules="{ required: true, min_value: 0}"
      >
        <b-form-group :label="'Slot '+(index+1)" label-for="h-number">
          <b-form-input
            v-model="price_data"
            @input="validateChecking"
            :state="getValidationState(validationContext)"
            type="number"
            :aria-describedby="'input-slot-'+index+'-feedback'"
          />
          <b-form-invalid-feedback :id="'input-slot-'+index+'-feedback'">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </validation-observer>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
export default {
  components:{
    ValidationProvider,
    ValidationObserver
  },
  props:{
    price: {type: Number, default: 0},
    index: {type: Number, required: true}
  },
  data(){
    return{
      price_data: this.price
    }
  },
  methods:{
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    validateChecking(){
      this.$emit("price_change", {price: parseInt(this.price_data), index: this.index})
    }
  }
}
</script>