<template>
  <div>
    <validation-observer
      ref="statEffect"
    > 
      <validation-provider
        name="Strength"
        v-slot="validationContext"
        :rules="{ required: true, min_value: 0}"
      >
        <b-form-group label="Strength" label-for="h-number">
          <b-input-group size="lg" prepend="%">
            <b-form-input
              class="percentage-input"
              v-model="config_data.value.str"
              :state="getValidationState(validationContext)"
              type="number"
              aria-describedby="input-str-feedback"
            />
            <b-form-invalid-feedback id="input-str-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </validation-provider>
      <validation-provider
        name="Default strength effect"
        v-slot="validationContext"
        :rules="{ required: true, min_value: 0}"
      >
        <b-form-group label="Default strength effect" label-for="h-number">
          <b-input-group size="lg" prepend="%">
            <b-form-input
              class="percentage-input"
              v-model="config_data.value.str_default"
              :state="getValidationState(validationContext)"
              type="number"
              aria-describedby="input-str-default-feedback"
            />
            <b-form-invalid-feedback id="input-str-default-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </validation-provider>
      <validation-provider
        name="Agility"
        v-slot="validationContext"
        :rules="{ required: true, min_value: 0}"
      >
        <b-form-group label="Agility" label-for="h-number">
          <b-input-group size="lg" prepend="%">
            <b-form-input
              class="percentage-input"
              v-model="config_data.value.agi"
              :state="getValidationState(validationContext)"
              type="number"
              aria-describedby="input-agi-feedback"
            />
            <b-form-invalid-feedback id="input-agi-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </validation-provider>
      <validation-provider
        name="Default agility effect"
        v-slot="validationContext"
        :rules="{ required: true, min_value: 0}"
      >
        <b-form-group label="Default agility effect" label-for="h-number">
          <b-input-group size="lg" prepend="%">
            <b-form-input
              class="percentage-input"
              v-model="config_data.value.agi_default"
              :state="getValidationState(validationContext)"
              type="number"
              aria-describedby="input-agi-default-feedback"
            />
            <b-form-invalid-feedback id="input-agi-default-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </validation-provider>
      <validation-provider
        name="Intelligent"
        v-slot="validationContext"
        :rules="{ required: true, min_value: 0}"
      >
        <b-form-group label="Intelligent" label-for="h-number">
          <b-input-group size="lg" prepend="%">
            <b-form-input
              v-model="config_data.value.intel"
              :state="getValidationState(validationContext)"
              type="number"
              aria-describedby="input-int-feedback"
            />
            <b-form-invalid-feedback id="input-int-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </validation-provider>
      <validation-provider
        name="Default intelligent effect"
        v-slot="validationContext"
        :rules="{ required: true, min_value: 0}"
      >
        <b-form-group label="Default intelligent effect" label-for="h-number">
          <b-input-group size="lg" prepend="%">
            <b-form-input
              v-model="config_data.value.intel_default"
              :state="getValidationState(validationContext)"
              type="number"
              aria-describedby="input-int-default-feedback"
            />
            <b-form-invalid-feedback id="input-int-default-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </validation-provider>
      <div style="text-align: right">
        <b-button @click="updateStatEffect" variant="success">Update</b-button>
      </div>
    </validation-observer>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import service from '../../service'
export default {
  components:{
    ValidationProvider,
    ValidationObserver
  },
  props:{
    config_data: {type: Object, default: null}
  },
  methods:{
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async updateStatEffect(){
      let validation = await this.$refs.statEffect.validate()
      if (!validation){
        this.$store.dispatch("pushErrorNotify", {
          text: "Stat effect validate error",
        });
      }
      else{
        let data = this.config_data;
        data.value.str = parseInt(data.value.str);
        data.value.agi = parseInt(data.value.agi);
        data.value.intel = parseInt(data.value.intel);
        await service.update(
          JSON.stringify(data)
        );
      }
    },
  }
}
</script>