<template>
  <div>
    <strong>{{ convertTier(tier.attrs.tier) }}</strong>
    <b-card>
      <b-form-group label="Drop rate" label-for="h-number">
        <b-input-group size="lg" prepend="%">
          <b-form-input
            v-model="tier.rate"
            :id="'tier-'+item_type+'.'+variant_type"
            type="number"
            @input="percentageCheck()"
          />
        </b-input-group>
      </b-form-group>
    </b-card>
  </div>
</template>
<script>
import TierConfig from "./TierConfig.vue"
export default {
  components:{
    TierConfig
  },
  props:{
    tier: {type: Object, default: null},
    item_type: {type: String, default: null},
    tierCollapseName:{type: String, default: null},
    variant_type:{type: Number, default: null}
  },
  computed:{
    collapseName(){
      return this.item_type+'.'+this.variant_type+'.'+this.tier.attrs.tier
    }
  },
  methods:{
    convertTier(value){
      if (this.item_type == "EQUIPMENT"){
        switch(value){
          case 1:
            return "Tier 1"
          case 2:
            return "Tier 2"
          case 3:
            return "Tier 3"
          case 4:
            return "Tier 4"
        }
      }
      if (this.item_type == "SMOOTHY"){
        switch(value){
          case 1:
            return "Mini-size"
          case 2:
            return "Medium size"
          case 3:
            return "Large size"
          case 4:
            return "Extra large size"
        }
      }
      if (this.item_type == "TOKEN"){
        return "Amount "+ value
      }
    },
    percentageCheck(){
      if (this.item_type == "TOKEN"){
        this.$emit("token_percentage_check")
      }
      else{
        this.$emit("percentage_check")
      } 
    }
  }
}
</script>
<style scoped>
  .card-body{
    padding: 0.5rem !important;
  }
</style>