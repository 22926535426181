<template>
  <div>
    <b-button class="w-full" v-b-toggle="collapse_id" variant="primary">{{ config.attrs.type }}</b-button>
    <b-collapse :id="collapse_id" class="mt-2">
      <b-card>
        <b-form-group label="Drop rate" label-for="h-number">
          <b-input-group size="lg" prepend="%">
            <b-form-input
              v-model="config.rate"
              id="h-number"
              type="number"
              @input="itemRateCheck()"
            />
          </b-input-group>
        </b-form-group>
        <div v-if="config.attrs.type!='TOKEN'" :id="variantId">
          <div v-for="(variant, index) in config.children" :key="index" class="mb-1">
            <variant-config
             :variant="variant"
             :type="config.attrs.type"
             @rateChange="variantRateCheck"
            />
          </div>
        </div>
        <div v-else id="tier.TOKEN">
          <div v-for="(tier, index) in config.children" :key="index" class="mb-1">
            <tier-config
              :tier="tier"
              :item_type="config.attrs.type"
              :variant_type="config.children[index].attrs.tier"
              @token_percentage_check="tokenRateCheck"
            />
          </div>
        </div>
      </b-card>
    </b-collapse>
  </div>
</template>
<script>
import VariantConfig from './VariantConfig.vue'
import TierConfig from './TierConfig.vue'
export default {
  components:{
    VariantConfig,
    TierConfig
  },
  props:{
    config: {type: Object, default: null},
    collapse_id: {type: String, default: null}
  },
  data(){
    return{
      showCreateModal: false,
      variant_name: null
    }
  },
  computed:{
    createNewText(){
      if (this.config.attrs.type!='TOKEN'){
        return {label:"Create new variant", name:"Variant name"}
      }
      return {label:"Create new tier", name:"Tier name"}
    },
    variantId(){
      return "variant." + this.config.attrs.type
    }
  },
  methods: {
    variantRateCheck(){
      let totalRate = 0;
      for (let i=0; i<this.config.children.length; i++){
        totalRate += parseInt(this.config.children[i].rate);
      }
      if (totalRate != 100){
        this.$store.commit("dropRateConfig/ADD_UNVALIDATE_RATE", this.variantId)
        document.getElementById(this.variantId).style.border = "2px solid red";
        document.getElementById(this.variantId).style.padding = "5px";
      }
      else{
        this.$store.commit("dropRateConfig/REMOVE_UNVALIDATE_RATE", this.variantId)
        document.getElementById(this.variantId).style.border = null;
        document.getElementById(this.variantId).style.padding = "0px";
      }
    },
    tokenRateCheck(){
      let totalRate = 0;
      for (let i=0; i<this.config.children.length; i++){
        totalRate += parseInt(this.config.children[i].rate);
      }
      if (totalRate != 100){
        this.$store.commit("dropRateConfig/ADD_UNVALIDATE_RATE", "tier.TOKEN")
        document.getElementById("tier.TOKEN").style.border = "2px solid red";
        document.getElementById("tier.TOKEN").style.padding = "5px";
      }
      else{
        this.$store.commit("dropRateConfig/REMOVE_UNVALIDATE_RATE", "tier.TOKEN")
        document.getElementById("tier.TOKEN").style.border = null;
        document.getElementById("tier.TOKEN").style.padding = "0px";
      }
    },
    itemRateCheck(){
      this.$emit('item_rate_check')
    },
    update(){
      this.$emit("updateDropRate")
    }
  }
}
</script>