import api from '@/libs/axios'

export default {
  async create(payload) {
    const response = await api.post('/admin/configs', payload)
    return response.data.data.new_document || null
  },
  async update(payload) {
    let response = await api.put('/admin/configs', payload)
    return response
  },
  async delete(payload) {
    const response = await api.delete('/admin/configs', {
      data: payload,
    })
    return response
  },
  async getList(params) {
    let response = await api.get('/admin/configs', {
      params,
    })
    return response
  },
  async get(params) {
    let response = await api.get('/admin/configs/detail', {
      params,
    })
    return response.data.data.document || null
  },
  parseIntRate(configData){
    for(let i of configData.value){
      i.rate = parseInt(i.rate)
      for (let j of i.children){
        j.rate = parseInt(j.rate);
        if (j.children){
          for (let k of j.children){
            k.rate = parseInt(k.rate);
          }
        }
      }
    }
    return configData
  }
}