<template>
  <div>
    <b-button class="w-full" v-b-toggle="collapseName" variant="success">{{ convertVariant(variant.attrs.variant) }}</b-button>
    <b-collapse :id="collapseName" class="mt-2">
      <b-card>
        <b-form-group label="Drop rate" label-for="h-number">
          <b-input-group size="lg" prepend="%">
            <b-form-input
              :class="className"
              v-model="variant.rate"
              type="number"
              @input="rateChange()"
            />
          </b-input-group>
        </b-form-group>
        <div :id="idName">
          <div v-for="(tier, index) in variant.children" :key="index">
            <tier-config
              :tier="tier"
              :item_type="type"
              :variant_type="variant.attrs.variant"
              @percentage_check="tierPercentageCheck"
            />
          </div>
        </div>
      </b-card>
    </b-collapse>
  </div>
</template>
<script>
import TierConfig from "./TierConfig.vue"
export default {
  components:{
    TierConfig
  },
  props:{
    variant: {type: Object, default: null},
    type: {type: String, default: null}
  },
  date(){
  },
  computed:{
    collapseName(){
      return 'collapse'+'.'+this.type+'.'+this.variant.attrs.variant
    },
    idName(){
      return 'variantId'+'.'+this.type+'.'+this.variant.attrs.variant
    },
    className(){
      return 'variantClass'+'.'+this.type+'.'+this.variant.attrs.variant
    }
  },
  methods:{
    convertVariant(value){
      if (this.type == "EQUIPMENT"){
        switch(value){
          case 1:
            return "White"
          case 2:
            return "Green"
          case 3:
            return "Orange"
          case 4:
            return "Purple"
        }
      }
      if (this.type == "SMOOTHY"){
        switch(value){
          case 1:
            return "Red"
          case 2:
            return "Yellow"
          case 3:
            return "Green"
        }
      }
    },
    tierPercentageCheck(){
      let totalRate = 0;
      for (let i=0; i<this.variant.children.length; i++){
        totalRate += parseInt(this.variant.children[i].rate);
      }
      if (totalRate != 100){
        this.$store.commit("dropRateConfig/ADD_UNVALIDATE_RATE", this.idName)
        document.getElementById(this.idName).style.border = "2px solid red";
        document.getElementById(this.idName).style.padding = "5px";
      }
      else{
        this.$store.commit("dropRateConfig/REMOVE_UNVALIDATE_RATE", this.idName)
        document.getElementById(this.idName).style.border = null;
        document.getElementById(this.idName).style.padding = "0px";
      }
    },
    rateChange(){
      this.$emit("rateChange");
    }
  }
}
</script>
<style scoped>
  .card-body{
    padding: 0.5rem !important;
  }
</style>